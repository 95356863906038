/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Do a deep-copy of basic JavaScript Objects or Arrays.
 */
export function deepCopy(value: T): T {
 return deepExtend(undefined, value) as T;
}

/**
 * Copy properties from source to target (recursively allows extension
 * of Objects and Arrays). Scalar values in the target are over-written.
 * If target is undefined, an object of the appropriate type will be created
 * (and returned).
 *
 * We recursively copy all child properties of plain Objects in the source- so
 * that namespace- like dictionaries are merged.
 *
 * Note that the target can be a function, in which case the properties in
 * the source Object are copied onto it as static properties of the Function.
 *
 * Note: we don't merge __proto__ to prevent prototype pollution
 */
export function deepExtend(target: unknown, source: unknown): unknown {
 if (!(source instanceof Object)) {
 return source;
 }

 switch (source.constructor) {
 case Date:
 // Treat Dates like scalars; if the target date object had any child
 // properties - they will be lost!
 const dateValue = source as Date;
 return new Date(dateValue.getTime());

 case Object:
 if (target === undefined) {
 target = {};
 }
 break;
 case Array:
 // Always copy the array source and overwrite the target.
 target = [];
 break;

 default:
 // Not a plain Object - treat it as a scalar.
 return source;
 }

 for (const prop in source) {
 // use isValidKey to guard against prototype pollution. See https://snyk.io/vuln/SNYK-JS-LODASH-450202
 if (!source.hasOwnProperty(prop) || !isValidKey(prop)) {
 continue;
 }
 (target as Record)[prop] = deepExtend(
 (target as Record)[prop],
 (source as Record)[prop]
 );
 }

 return target;
}

function isValidKey(key: string): boolean {
 return key !== '__proto__';
}
